import React from "react";
import { Link } from "../../../components/Link/Link";
import { LoginPage, SignUpPage, SignUpStep } from "../../../pages";

export function FoodNavigatorNonPremiumLinks() {
  return (
    <div className="mb-2 flex items-center gap-x-2">
      <span className="text-xs bg-gold text-darker-grey px-3 py-1 rounded-full">Premium</span>
      <p>
        <span>Access all food items by </span>
        <Link to={LoginPage} query={{ redirectTo: document.location.href }}>
          signing in
        </Link>
        <span> or </span>
        <Link to={SignUpPage} params={{ step: SignUpStep.ACCOUNT, content: "food-navigator" }}>
          signing up for a free trial.
        </Link>
      </p>
    </div>
  );
}
