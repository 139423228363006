import React, { useCallback, useContext, useMemo, useState } from "react";
import { sendEvent } from "../../../utils/gtm";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";
import { MdArrowDropUp } from "@react-icons/all-files/md/MdArrowDropUp";
import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown";
import { FoodExplorerDefinedTag } from "../types";
import useWindowWidth from "../../../hooks/useWindowWidth/useWindowWidth";
import { toCMSAssetUrl } from "../../../utils/utils";
import { Link } from "@reach/router";

const idleStyle = "whitespace-nowrap px-4 py-2 text-lg rounded-md bg-[#E7EBF2] font-normal text-black";
const selectedStyle = "whitespace-nowrap px-4 py-2 text-lg rounded-md bg-blue font-medium text-white";

export const FoodExplorerTagsComp = React.memo(Tags);

function Tags() {
  const { tag: tagSlug, modeId, tags } = useContext(FoodExplorerStateContext);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const windowWidth = useWindowWidth();

  const onClickOnTag = useCallback(
    (tag: FoodExplorerDefinedTag) => () => {
      setMenuOpen(false);
      sendEvent({
        eventName: "food_navigator",
        action: "foodnavigator_ingredient_category",
        payload: {
          tag: tag.slug,
        },
      });
    },
    []
  );
  const selectedTag = useMemo(() => tags.filter((tag) => tag.slug === tagSlug)[0], [tags, tagSlug]);

  const topTags = windowWidth > 1100 ? tags.slice(0, 6) : windowWidth > 767 ? tags.slice(0, 3) : [];

  return (
    <>
      <div className="md:flex w-full hidden gap-x-2">
        {topTags.map((tag) => (
          <React.Fragment key={tag.slug}>
            <Link
              onClick={onClickOnTag(tag)}
              to={tag.slug === "all" ? `../${modeId}` : `../${modeId}/${tag.slug}`}
              className={
                tag.slug === tagSlug || (tag.slug === "all" && tagSlug === undefined)
                  ? selectedStyle
                  : idleStyle
              }
            >
              {tag.label}
            </Link>
          </React.Fragment>
        ))}
      </div>

      <div className="relative w-full md:w-[350px] md:min-w-[260px]">
        <div
          className={`${idleStyle} cursor-pointer flex w-full md:min-w-max items-center`}
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <span className="flex-grow">
            {selectedTag === undefined || topTags.includes(selectedTag) ? "All categories" : selectedTag.label}
          </span>
          {isMenuOpen ? <MdArrowDropUp size={25} /> : <MdArrowDropDown size={25} />}
        </div>

        {isMenuOpen && (
          <div className="absolute bg-white md:shadow-md rounded-sm p-5 max-h-96 overflow-auto z-20 right-0 w-full border-b-2 border-lighter-grey md:border-none">
            <ul className="list-none m-0 p-0">
              {tags.map((tag) => (
                <li key={tag.id}>
                  <Link
                    onClick={onClickOnTag(tag)}
                    to={tag.slug === "all" ? `../${modeId}` : `../${modeId}/${tag.slug}`}
                    className="py-2 border-b border-lighter-grey font-normal text-black w-full md:min-w-max flex gap-x-2 md:gap-x-1 items-center"
                  >
                    {tag.image && (
                      <div className="w-10 md:w-9">
                        <img
                          className="w-full"
                          src={toCMSAssetUrl(tag.image.id, 60, 60, "inside")}
                          alt={tag.slug}
                        />
                      </div>
                    )}
                    <span>{tag.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
