import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useContext } from "react";
import Button from "../../../components/Button/Button";
import { useNavigate } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { OnboardingGenderPage } from "../../onboarding/pages";
import { sendEvent } from "../../../utils/gtm";
import { useTranslations } from "../translations";

export function PremiumMembership() {
  const { premium } = useContext(LoginContext);
  const navigate = useNavigate();
  const tt = useTranslations();

  const handleTryItToday = useCallback(() => {
    sendEvent({
      eventName: "food_navigator",
      action: "foodnavigator_ingredient_itempage_banner_signup",
    });
    navigate({ to: OnboardingGenderPage });
  }, [navigate]);

  return !premium ? (
    <div className="flex flex-col">
      <div className="uppercase text-center after:w-full after:h-px after:bg-dark-grey after:absolute after:left-0 after:bottom-1/2 after:-z-1 relative">
        <span className="bg-white px-2 text-dark-grey text-xs tracking-[0.18em]">
          {tt.premiumMembershipText}
        </span>
      </div>
      <div className="flex flex-col md:flex-row gap-3 rounded-lg bg-[#EDF4F5]">
        <div className="md:w-1/2 flex flex-col justify-center px-6 md:px-14">
          <h2 className="text-[#3C3C3C] mt-14 md:mt-0 text-[1.75rem] lg:text-4xl xl:text-[2.75rem] leading-tight xl:leading-tight">
            {tt.premiumMembershipBannerHeading}
          </h2>
          <p className="text-base leading-5 mt-2 lg:text-lg xl:text-2xl lg:leading-none">
            {tt.premiumMembershipContent}
          </p>
          <div className="flex flex-col mt-6 md:mt-10 gap-y-2 md:items-start justify-start">
            <Button size="small" className="lg:!py-3" onClick={handleTryItToday}>
              {tt.button.tryItToday}
            </Button>
            <span className="ml-1">
              {tt.textContentBelow.itsQuick} <strong>{tt.textContentBelow.free}</strong>
            </span>
          </div>
        </div>
        <div className="md:w-1/2 pt-5 items-end hidden md:flex">
          <StaticImage
            src="../../../../static/images/food-explorer/premium-membership-desktop.png"
            quality={98}
            placeholder="none"
            width={1024}
            objectFit="contain"
            alt="quiz"
            className="w-full"
          />
        </div>

        <div className="md:w-1/2 md:hidden">
          <StaticImage
            src="../../../../static/images/food-explorer/premium-membership-mobile.png"
            quality={98}
            placeholder="none"
            width={768}
            objectFit="contain"
            alt="quiz"
            className="w-full"
          />
        </div>
      </div>
      <div className="w-full h-px bg-dark-grey mt-3" />
    </div>
  ) : null;
}
