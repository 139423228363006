import React, { ReactNode, useMemo } from "react";
import { FoodExplorerFoodItem } from "../types";
import { colorByProtein } from "../utils";

export function itemWithProtein(
  WrappedComponent: React.MemoExoticComponent<
    ({
      item,
      isLocked,
      children,
    }: {
      item: FoodExplorerFoodItem;
      isLocked: boolean;
      children: ReactNode;
    }) => JSX.Element
  >
) {
  return function WithProteinPercentage(props: { item: FoodExplorerFoodItem; isLocked: boolean }) {
    const percent = useMemo(() => props.item.proteinPercent, [props.item.proteinPercent]);
    const textColor = useMemo(() => colorByProtein(percent), [percent]);
    return (
      <WrappedComponent item={props.item} isLocked={props.isLocked}>
        <div className="flex justify-center font-bold text-base" style={{ color: textColor }}>
          {percent}
        </div>
      </WrappedComponent>
    );
  };
}
