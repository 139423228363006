import { useMatch } from "@reach/router";
import React, { createContext, Dispatch, SetStateAction, useState } from "react";
import { useFoodExplorerFilteredItemsWithTag } from "./hooks/useFoodExplorerFilteredItemsWithTag";
import { useFoodExplorerItems } from "./hooks/useFoodExplorerItems";
import { FoodExplorerMode, useFoodExplorerModes } from "./hooks/useFoodExplorerModes";
import { useFoodExplorerTags } from "./hooks/useFoodExplorerTags";
import { FoodExplorerDefinedTag, FoodExplorerFoodItem, FoodExplorerTags } from "./types";

type IFoodExplorerStateContext = {
  modeId: FoodExplorerMode;
  tag?: string;
  tags: FoodExplorerDefinedTag[];
  items: FoodExplorerFoodItem[];
  isInfoPopUpOpen: boolean;
  setInfoPopUpOpen: Dispatch<SetStateAction<boolean>>;
};

export const FoodExplorerStateContext = createContext<IFoodExplorerStateContext>({
  modeId: FoodExplorerMode.CARBS,
  tags: [],
  items: [],
  isInfoPopUpOpen: false,
  setInfoPopUpOpen: () => false,
});

export const FoodExplorerStateProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    items: FoodExplorerFoodItem[];
    mode: FoodExplorerMode;
    tags: FoodExplorerTags[];
  }>
> = (props) => {
  const { children } = props;
  const { defaultMode } = useFoodExplorerModes(); // carbs

  const matchTag = useMatch("/food-navigator/:mode/:tag");
  const modeId = props.mode ?? defaultMode.id;
  const tag = matchTag?.tag;

  const [items] = useFoodExplorerItems(props.items);
  const [tags] = useFoodExplorerTags(props.tags);
  const [filteredByTag] = useFoodExplorerFilteredItemsWithTag(items, tag);
  const [isInfoPopUpOpen, setInfoPopUpOpen] = useState(false);

  return (
    <FoodExplorerStateContext.Provider
      value={{
        modeId,
        tag,
        tags,
        items: filteredByTag,
        isInfoPopUpOpen,
        setInfoPopUpOpen,
      }}
    >
      {children}
    </FoodExplorerStateContext.Provider>
  );
};
