import React from "react";
import { FoodExplorerBrowser } from "./browser/FoodExplorerBrowser";
import { FoodExplorerModeInfo } from "./components/FoodExplorerModeInfo";
import { FoodExplorerModeLegendHorizontal } from "./components/FoodExplorerModeLegendHorizontal";
import { FoodExplorerModeSwitcher } from "./components/FoodExplorerModeSwitcher";
import { FoodExplorerTagsComp } from "./components/FoodExplorerTags";
import { FoodExplorerTitle } from "./components/FoodExplorerTitle";
import { FoodExplorerStateProvider } from "./FoodExplorerStateProvider";
import { PremiumMembership } from "./foodPage/PremiumMembership";
import { FoodExplorerMode } from "./hooks/useFoodExplorerModes";
import { FoodExplorerFoodItem, FoodExplorerTags } from "./types";

type Props = {
  items: FoodExplorerFoodItem[];
  mode: FoodExplorerMode;
  tags: FoodExplorerTags[];
};

export function FoodExplorer({ items, mode, tags }: Props) {
  return (
    <>
      <FoodExplorerStateProvider items={items} mode={mode} tags={tags}>
        <div className="max-w-[1440px] flex flex-col w-full px-6 py-10 md:p-16 mx-auto">
          <div className="flex w-full gap-12 mb-4 items-center">
            <div className="flex lg:w-7/12">
              <FoodExplorerTitle />
            </div>
            {/* legend, only desktop */}
            <div className="hidden lg:flex lg:w-5/12">
              <div className="flex flex-col w-full h-full gap-7 items-end">
                <FoodExplorerModeLegendHorizontal />
              </div>
            </div>
          </div>
          <div className="w-full mb-4 flex">
            <FoodExplorerTagsComp />
          </div>
          <div className="flex flex-col w-full mb-12">
            <FoodExplorerBrowser />
          </div>

          <div className="sticky w-full bottom-xl z-10 flex justify-center">
            <FoodExplorerModeSwitcher />
          </div>
          {/* pop up - only mobile */}
          <FoodExplorerModeInfo />
        </div>
        <div className="max-w-[1440px] w-full mx-auto mb-10 px-6 md:px-16">
          <PremiumMembership />
        </div>
      </FoodExplorerStateProvider>
    </>
  );
}
