import { useCallback, useMemo } from "react";
import { useTranslations } from "../translations";

export enum FoodExplorerMode {
  SATIETY = "satiety",
  CARBS = "carbs",
  PROTEIN = "protein",
}

export function useFoodExplorerModes() {
  const tt = useTranslations();

  const defaultMode = useMemo(
    () => ({
      id: FoodExplorerMode.CARBS,
      short: tt.foodExplorerModes.carbs.short,
      long: tt.foodExplorerModes.carbs.long,
      description: tt.foodExplorerModes.carbs.description,
      legends: [tt.foodExplorerModes.carbs.moreCarbsLegend, tt.foodExplorerModes.carbs.fewerCarbsLegend],
    }),
    [tt.foodExplorerModes.carbs]
  );

  const modes = useMemo(
    () => [
      defaultMode,
      {
        id: FoodExplorerMode.PROTEIN,
        short: tt.foodExplorerModes.protein.short,
        long: tt.foodExplorerModes.protein.long,
        description: tt.foodExplorerModes.protein.description,
        legends: [
          tt.foodExplorerModes.protein.lessProteinLegend,
          tt.foodExplorerModes.protein.moreProteinLegend,
        ],
      },
      {
        id: FoodExplorerMode.SATIETY,
        short: tt.foodExplorerModes.satiety.short,
        long: tt.foodExplorerModes.satiety.long,
        description: tt.foodExplorerModes.satiety.description,
        legends: [
          tt.foodExplorerModes.satiety.lessSatietyLegend,
          tt.foodExplorerModes.satiety.moreSatietyLegend,
        ],
      },
    ],
    [defaultMode, tt.foodExplorerModes]
  );

  const getModeByModeId = useCallback(
    (modeId: FoodExplorerMode) => modes.find((m) => m.id === modeId),
    [modes]
  );

  return {
    defaultMode,
    modes,
    getModeByModeId,
  };
}
