import { MdClose } from "@react-icons/all-files/md/MdClose";
import React, { useCallback, useContext } from "react";
import { Link } from "../../../components/Link/Link";
import { SatietyScorePage } from "../../../pages";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";
import { FoodExplorerMode, useFoodExplorerModes } from "../hooks/useFoodExplorerModes";
import { useTranslations } from "../translations";
import { FoodExplorerModeLegendVertical } from "./FoodExplorerModeLegendVertical";

export const FoodExplorerModeInfo = React.memo(ModeInfo);

function ModeInfo() {
  const { modeId, isInfoPopUpOpen, setInfoPopUpOpen } = useContext(FoodExplorerStateContext);
  const { getModeByModeId } = useFoodExplorerModes();
  const mode = getModeByModeId(modeId);
  const tt = useTranslations();

  const handleClosePopUp = useCallback(() => setInfoPopUpOpen(false), [setInfoPopUpOpen]);

  return isInfoPopUpOpen ? (
    <>
      <div
        className="bg-black bg-opacity-60 fixed z-9999 justify-center left-0 shrink-0 top-0 w-full h-full"
        onClick={handleClosePopUp}
      ></div>
      <div className="bg-white fixed z-9999 left-1/2 -translate-x-1/2 top-0 rounded-lg p-6 mt-32 shadow-sm lg:max-w-lg w-11/12">
        <div className="flex flex-col gap-6">
          <div className="flex justify-between">
            <h4 className="text-xl">{mode?.long}</h4>
            <MdClose size={24} onClick={handleClosePopUp} className="cursor-pointer" />
          </div>
          <div className="flex flex-row gap-5">
            <FoodExplorerModeLegendVertical />
            <div>
              <p dangerouslySetInnerHTML={{ __html: mode?.description ?? "" }} />
              {modeId === FoodExplorerMode.SATIETY && (
                <Link to={SatietyScorePage} target="_blank">
                  {tt.learnMore}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
