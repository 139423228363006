import React, { useMemo } from "react";
import { toCMSAssetUrl } from "../../../utils/utils";
import { FoodExplorerFoodItem } from "../types";

export const FoodExplorerBrowserItem = React.memo(Item);

function Item({
  item,
  children,
  isLocked,
}: {
  item: FoodExplorerFoodItem;
  children: React.ReactNode;
  isLocked: boolean;
}) {
  const title = useMemo(() => item.translations[0]?.title, [item]);
  const image = useMemo(() => toCMSAssetUrl(item.image.id, 180, 180, "inside", 80), [item]);
  return (
    <div className="flex flex-col w-full">
      <div
        className={`${
          isLocked ? "blur-[8px]" : ""
        } w-full aspect-[1/1] flex justify-center items-end overflow-hidden relative`}
      >
        <img className="w-full" src={image} alt={isLocked ? "" : title} />
        {isLocked && <div className="w-full h-full absolute"></div>}
      </div>
      {children}
      <h3
        className={`${
          isLocked ? "blur-[8px]" : ""
        } flex font-normal text-center items-end justify-center m-0 text-sm w-full text-black-100 mt-0.5`}
      >
        {title}
      </h3>
    </div>
  );
}
