import { FoodExplorerFoodItem } from "./types";
import { FoodExplorerMode } from "./hooks/useFoodExplorerModes";

type Item = FoodExplorerFoodItem;

const carbsToRange = (score: number) => (score < 6 ? 0 : score < 10 ? 1 : score < 20 ? 2 : 3);
const proteinToRange = (score: number) => (score < 10 ? 0 : score < 20 ? 1 : score < 30 ? 2 : 3);
const satietyToRange = (score: number) => (score < 15 ? 0 : score < 40 ? 1 : score < 60 ? 2 : 3);

export const colorByCarbs = (score: number) =>
  ["#008E47", "#2FCE78", "#f2bd00", "#f28e00"][carbsToRange(score)];

export const colorByProtein = (score: number) =>
  ["#f28e00", "#f2bd00", "#2FCE78", "#008E47"][proteinToRange(score)];

export const colorBySatietyScore = (score: number) =>
  ["#f28e00", "#f2bd00", "#2FCE78", "#008E47"][satietyToRange(score)];

const sortByProteinPercent = (a: Item, b: Item) => a.proteinPercent - b.proteinPercent;

const sortByCarbs = (a: Item, b: Item) => b.netCarbs - a.netCarbs;

const sortBySatiety = (a: Item, b: Item) => a.satietyScore - b.satietyScore;

export const sortFoodItems = (foodItems: Item[], mode: FoodExplorerMode) => {
  if (mode === FoodExplorerMode.PROTEIN) {
    return foodItems.sort(sortByProteinPercent);
  } else if (mode === FoodExplorerMode.CARBS) {
    return foodItems.sort(sortByCarbs);
  }

  return foodItems.sort(sortBySatiety);
};
