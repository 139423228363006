import React, { useContext } from "react";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";
import { useFoodExplorerModes } from "../hooks/useFoodExplorerModes";
import { FoodExplorerModeInfoIcon } from "../components/FoodExplorerModeInfoIcon";

import { useTranslations } from "../translations";

export const FoodExplorerTitle = React.memo(Title);

function Title() {
  const { modeId: mode } = useContext(FoodExplorerStateContext);
  const { modes, defaultMode } = useFoodExplorerModes();
  const { long } = modes.find((m) => m.id === mode) ?? defaultMode;

  const tt = useTranslations();

  return (
    <div className="flex flex-col">
      <span className="uppercase text-sm">beta</span>
      <div className="flex flex-col md:flex-row md:gap-2 md:items-center leading-none">
        <h1 className="text-[1.9rem] md:text-[2.2rem] xl:text-[2.5rem] tracking-tighter whitespace-nowrap leading-8 md:leading-none">
          {tt.foodNavigatorHeading}
        </h1>
        <span className="hidden md:flex md:text-[2rem] xl:text-[2.2rem]">|</span>
        <h2 className="flex items-center font-normal text-[1.7rem] md:text-[2rem] xl:text-[2.2rem]">
          <span>{long}</span> <FoodExplorerModeInfoIcon />
        </h2>
      </div>
    </div>
  );
}
