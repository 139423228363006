import { FoodExplorerFoodItem } from "../types";

const isTag = (tag: string) => (i: FoodExplorerFoodItem) =>
  i.tags.find((t) => t.tag.translations[0].tagSlug === tag);

export function useFoodExplorerFilteredItemsWithTag(items: FoodExplorerFoodItem[], tag?: string) {
  if (!tag) return [items];

  const onlyWithTag = items.filter(isTag(tag));
  return [onlyWithTag];
}
