import React, { useCallback } from "react";
import { useContext } from "react";
import { InfoIcon } from "../../../icons/InfoIcon";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";

export const FoodExplorerModeInfoIcon = React.memo(ModeInfoIcon);

function ModeInfoIcon() {
  const { setInfoPopUpOpen } = useContext(FoodExplorerStateContext);

  const handleOpenPopUp = useCallback(() => setInfoPopUpOpen(true), [setInfoPopUpOpen]);

  return (
    <span className="ml-2 cursor-pointer flex items-center" onClick={handleOpenPopUp}>
      <InfoIcon className="text-light-blue" />
    </span>
  );
}
