import { Link } from "@reach/router";
import React, { useCallback, useContext } from "react";
import { sendEvent } from "../../../utils/gtm";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";
import { FoodExplorerMode, useFoodExplorerModes } from "../hooks/useFoodExplorerModes";

// the tag is undefined when there is no tag
// selected, usually when is loaded for the first time
const withTagOrWithout = (modeId: FoodExplorerMode, tag?: string) =>
  tag ? `../${modeId}/${tag}` : `../${modeId}`;

export const FoodExplorerModeSwitcher = React.memo(ModeSwitcher);

function ModeSwitcher() {
  const { modeId, tag } = useContext(FoodExplorerStateContext);
  const { modes } = useFoodExplorerModes();

  const onClickOnMode = useCallback(
    (mode: FoodExplorerMode) => () => {
      sendEvent({
        eventName: "food_navigator",
        action: "foodnavigator_switcher",
        payload: {
          mode,
        },
      });
    },
    []
  );

  return (
    <div className="flex gap-1 md:gap-4 text-sm md:text-lg bg-[#ececec] rounded-full p-1 select-none items-center shadow-lg">
      {modes.map((m) => (
        <div
          key={m.id}
          className={`rounded-full px-3 py-1.5 md:px-4 md:py-1 transition-all w-full flex justify-center duration-300 ${
            modeId === m.id ? `bg-blue` : "hover:bg-white bg-transparent"
          }`}
        >
          {modeId === m.id ? (
            <span className="text-white font-medium whitespace-nowrap">{m.long}</span>
          ) : (
            <Link
              onClick={onClickOnMode(m.id)}
              className="text-dark-grey font-normal whitespace-nowrap"
              to={withTagOrWithout(m.id, tag)}
            >
              {m.long}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
}
