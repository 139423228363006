import { t } from "@lingui/macro";

export function useTranslations() {
  return {
    foodExplorerPageTitle: t({
      id: "FoodExplorerPage.title",
      message: "Food Navigator",
      comment: "The HTML page title.",
    }),

    foodNavigatorHeading: t({
      id: "FoodExplorerPage.foodNavigatorHeading",
      message: "Food Navigator",
    }),

    quantities: {
      amountInGrams: (rawAmount: number, decimalPlaces = 2) => {
        const factor = Math.pow(10, Math.max(decimalPlaces, 0));
        const amount = Math.round(rawAmount * factor) / factor;
        return t({
          id: "FoodExplorerPage.quantities.amountInGrams",
          message: `${amount}g`,
        });
      },
      timeInMinutes: (time: number | string) =>
        t({
          id: "FoodExplorerPage.quantities.timeInMinutes",
          message: `${time} min`,
        }),
    },

    servingSizeValue: (serving: number) =>
      t({
        id: "FoodExplorerPage.servingSizeValue",
        message: `Serving size ${serving} g`,
      }),

    breadcrumbLinks: {
      foodNavigator: t({
        id: "FoodExplorerPage.breadcrumbLinks.foodNavigator",
        message: "Food Navigator",
      }),
    },

    showMoreButton: t({
      id: "FoodExplorerPage.showMoreButton",
      message: "Show more",
    }),

    relatedRecipesHeading: t({
      id: "FoodExplorerPage.relatedRecipesHeading",
      message: "Recipes",
    }),

    foodModeScore: {
      protienPercentage: t({
        id: "FoodExplorerPage.foodModeScore.protienPercentage",
        message: "Protein (%)",
      }),
      satietyScore: t({
        id: "FoodExplorerPage.foodModeScore.satietyScore",
        message: "Satiety",
      }),
      netCarbs: t({
        id: "FoodExplorerPage.foodModeScore.netCarbs",
        message: "Net carbs (g)",
      }),
    },

    nutrients: {
      totalCarbs: t({
        id: "FoodExplorerPage.nutrients.totalCarbs",
        message: "Total carbs",
      }),
      fiber: t({
        id: "FoodExplorerPage.nutrients.fiber",
        message: "Fiber",
      }),
      sugar: t({
        id: "FoodExplorerPage.nutrients.sugar",
        message: "Sugar",
      }),
      protein: t({
        id: "FoodExplorerPage.nutrients.protein",
        message: "Protein",
      }),
      fat: t({
        id: "FoodExplorerPage.nutrients.fat",
        message: "Fat",
      }),
      calories: t({
        id: "FoodExplorerPage.nutrients.calories",
        message: "Calories",
      }),
    },

    premiumMembershipText: t({
      id: "FoodExplorerPage.premiumMembershipText",
      message: "Premium membership",
    }),

    premiumMembershipBannerHeading: t({
      id: "FoodExplorerPage.premiumMembershipBannerHeading",
      message: "Enjoy a satisfying and effective way of eating",
    }),

    premiumMembershipContent: t({
      id: "FoodExplorerPage.premiumMembershipContent.content",
      message: "Let yourself be guided by our experts and your wellness goals will be within reach.",
    }),

    textContentBelow: {
      itsQuick: t({
        id: "FoodExplorerPage.textContentBelow.itsQuick",
        message: `It's quick and`,
      }),
      free: t({
        id: "FoodExplorerPage.textContentBelow.free",
        message: `free.`,
      }),
    },

    showMore: t({
      id: "FoodExplorerPage.showMore",
      message: "show more",
    }),

    showLess: t({
      id: "FoodExplorerPage.showLess",
      message: "show less",
    }),

    learnMore: t({
      id: "FoodExplorerPage.link.learnMore",
      message: "Learn more",
    }),

    button: {
      tryItToday: t({
        id: "FoodExplorerPage.button.takeTheSurvey",
        message: "Take the survey!",
      }),

      showAll: t({
        id: "FoodExplorerPage.button.showAll",
        message: "Show all",
      }),
    },

    premiumFoodItemPopup: {
      title: t({
        id: "FoodExplorerPage.premiumFoodItemPopup.title",
        message: "Unlock your health with Diet Doctor Plus",
      }),
      description: t({
        id: "FoodExplorerPage.premiumFoodItemPopup.description",
        message:
          "Start your free trial to access this and other DD+ benefits that help make healthy eating easy as pie.",
      }),
      startTrialBtn: t({
        id: "FoodExplorerPage.premiumFoodItemPopup.startTrialBtn",
        message: "Start my free trial",
      }),
      learnMoreBtn: t({
        id: "FoodExplorerPage.premiumFoodItemPopup.learnMoreBtn",
        message: "Learn more",
      }),
      note: t({
        id: "FoodExplorerPage.premiumFoodItemPopup.note",
        message: "No commitment, cancel anytime. Already a member?",
      }),
      signInHere: t({
        id: "FoodExplorerPage.premiumFoodItemPopup.signInHere",
        message: "Sign in here",
      }),
    },

    foodExplorerModes: {
      satiety: {
        short: t({
          id: "FoodExplorerPage.foodExplorerModes.satiety.short",
          message: "Satiety",
        }),
        long: t({
          id: "FoodExplorerPage.foodExplorerModes.satiety.long",
          message: "Satiety score",
        }),
        description: t({
          id: "FoodExplorerPage.foodExplorerModes.satiety.description",
          message:
            "Our satiety score predicts a food’s ability to provide feelings of fullness after and between meals. The higher the score, the more satisfying the food is likely to be relative to the energy it contains. The score is an average and actual values may vary.",
        }),
        lessSatietyLegend: t({
          id: "FoodExplorerPage.foodExplorerModes.satiety.lessSatietyLegend",
          message: "less satiety",
        }),
        moreSatietyLegend: t({
          id: "FoodExplorerPage.foodExplorerModes.satiety.moreSatietyLegend",
          message: "more satiety",
        }),
      },

      carbs: {
        short: t({
          id: "FoodExplorerPage.foodExplorerModes.carbs.short",
          message: "Carbs (g)",
        }),
        long: t({
          id: "FoodExplorerPage.foodExplorerModes.carbs.long",
          message: "Net carbs (g)",
        }),
        description: t({
          id: "FoodExplorerPage.foodExplorerModes.carbs.description",
          message:
            "The net carb number tells you how many grams of net carbs per 100 grams (3.5 ounces) of food. (Net carbs are the total carbs minus dietary fiber.) The number is an average and actual values may differ by brand, preparation method, and the natural variation among food products.",
        }),
        fewerCarbsLegend: t({
          id: "FoodExplorerPage.foodExplorerModes.carbs.fewerCarbsLegend",
          message: "fewer carbs",
        }),
        moreCarbsLegend: t({
          id: "FoodExplorerPage.foodExplorerModes.carbs.moreCarbsLegend",
          message: "more carbs",
        }),
      },

      protein: {
        short: t({
          id: "FoodExplorerPage.foodExplorerModes.protein.short",
          message: "Protein (%)",
        }),
        long: t({
          id: "FoodExplorerPage.foodExplorerModes.protein.long",
          message: "Protein (%)",
        }),
        description: t({
          id: "FoodExplorerPage.foodExplorerModes.protein.description",
          message:
            "The number tells you how much of a food’s calories come from protein.\nThe higher the protein percentage, the more protein you’ll get per calorie.\nThe number is an average and actual values may differ by brand, preparation method, and the natural variation among food products.",
        }),
        lessProteinLegend: t({
          id: "FoodExplorerPage.foodExplorerModes.protein.lessProteinLegend",
          message: "less protein",
        }),
        moreProteinLegend: t({
          id: "FoodExplorerPage.foodExplorerModes.protein.moreProteinLegend",
          message: "more protein",
        }),
      },
    },
  };
}
