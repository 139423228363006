import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ItemWithCarbs, ItemWithProtein, ItemWithSatietyScore } from ".";
import { FoodExplorerFoodPage } from "../../../pages";
import { sendEvent } from "../../../utils/gtm";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";
import { FoodExplorerMode } from "../hooks/useFoodExplorerModes";
import useWindowWidth from "../../../hooks/useWindowWidth/useWindowWidth";
import Button from "../../../components/Button/Button";
import { useTranslations } from "../translations";
import { sortFoodItems } from "../utils";
import { useUser } from "../../../hooks/useUser/useUser";
import { PremiumFoodItemPopUp } from "./PremiumFoodItemPopup";
import { FoodNavigatorNonPremiumLinks } from "../components/FoodNavigatorNonPremiumLinks";
import { Link } from "gatsby";

export const FoodExplorerBrowser = React.memo(Browser);

function Browser() {
  const { items, modeId: mode, tag } = useContext(FoodExplorerStateContext);
  const { loggedIn, isPremium } = useUser();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 1024;
  const [isShowAll, setShowAll] = useState(isMobile);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const tt = useTranslations();

  const ItemWrapper = useMemo(
    () =>
      mode === FoodExplorerMode.CARBS
        ? ItemWithCarbs
        : mode === FoodExplorerMode.PROTEIN
        ? ItemWithProtein
        : ItemWithSatietyScore,
    [mode]
  );

  useEffect(() => {
    setShowAll(isMobile);
  }, [tag, isMobile]);

  const topPriorityItems = useMemo(() => {
    return items.slice(0, 24);
  }, [items]);

  const defaultItems = useMemo(
    () =>
      isShowAll
        ? isMobile
          ? [...sortFoodItems(items, mode).reverse()]
          : sortFoodItems(items, mode)
        : sortFoodItems(topPriorityItems, mode),
    [items, topPriorityItems, mode, isShowAll, isMobile]
  );

  const onClickOnItem = useCallback(
    (id: string) => () => {
      sendEvent({
        eventName: "food_navigator",
        action: "foodnavigator_ingredient_item",
        payload: {
          id,
        },
      });
    },
    []
  );

  const rowNumbers =
    defaultItems.length % 8 > 0 ? ~~(defaultItems.length / 8) + 1 : ~~(defaultItems.length / 8);

  const itemHeight = windowWidth >= 1024 ? `${rowNumbers * 215}px` : "auto";

  return (
    <>
      {!loggedIn && !isPremium && <FoodNavigatorNonPremiumLinks />}

      <div
        className={`flex flex-row lg:flex-col-reverse flex-wrap gap-y-2 w-full lg:gap-y-0 lg:place-content-start`}
        style={{ height: itemHeight, justifyContent: "start" }}
      >
        {defaultItems.map((item, idx) => (
          <div key={item?.id} className="w-1/3 md:w-1/5 lg:w-1/8 lg:h-[215px]">
            {!loggedIn && !isPremium && item.isPremiumItem ? (
              <div className="flex items-start h-full" onClick={() => setPopupOpen(true)}>
                <ItemWrapper item={item} isLocked={item.isPremiumItem} />
              </div>
            ) : (
              <Link
                onClick={onClickOnItem(item.id)}
                to={`/food/${item.translations[0].slug}`}
                state={{ mode }}
                className="flex items-start h-full"
              >
                <ItemWrapper item={item} isLocked={false} />
              </Link>
            )}
          </div>
        ))}
      </div>
      {!isShowAll && items.length > 24 && windowWidth >= 1024 && (
        <div className="flex justify-center my-6 md:mt-20 md:mb-10">
          <Button color="black" onClick={() => setShowAll(true)}>
            {tt.button.showAll}
          </Button>
        </div>
      )}
      {isPopupOpen && <PremiumFoodItemPopUp onClose={() => setPopupOpen(false)} />}
    </>
  );
}
