import React, { useContext } from "react";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";
import { useFoodExplorerModes } from "../hooks/useFoodExplorerModes";

export const FoodExplorerModeLegendVertical = React.memo(Legend);

function Legend() {
  const { modeId } = useContext(FoodExplorerStateContext);
  const { getModeByModeId } = useFoodExplorerModes();
  const mode = getModeByModeId(modeId);

  return (
    <div className="flex flex-row lg:hidden">
      <div className="mr-3 flex flex-col h-full justify-between uppercase tracking-wider text-sm font-medium text-grey">
        <div className="flex" style={{ writingMode: "vertical-lr" }}>
          {mode?.legends[1]}
        </div>
        <div className="flex" style={{ writingMode: "vertical-lr" }}>
          {mode?.legends[0]}
        </div>
      </div>
      <div
        className="w-1.5 h-full min-h-[240px] rounded-md"
        style={{
          backgroundImage: `linear-gradient(
      to top,
      #F28E00 0%,
      #F2BD00 33%,
      #2FCE78 66%,
      #008E47 100%
    )`,
        }}
      ></div>
    </div>
  );
}
