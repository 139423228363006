import { Redirect, Router } from "@reach/router";

import React from "react";
import Route from "../../components/Route/Route";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { FoodExplorer } from "./FoodExplorer";
import { FoodExplorerMode } from "./hooks/useFoodExplorerModes";
import { useTranslations } from "./translations";
import { FoodExplorerFoodItem, FoodExplorerTags } from "./types";

// ts-prune-ignore-next
export default function FoodExplorerPage({
  pageContext: { prefix, items, tags },
}: {
  pageContext: { prefix: string; items: FoodExplorerFoodItem[]; tags: FoodExplorerTags[] };
}) {
  const tt = useTranslations();

  return (
    <>
      <SEOReactHelmet title={tt.foodExplorerPageTitle} />

      <Router basepath={prefix} style={{ width: "100%" }}>
        <Redirect from="/" to={FoodExplorerMode.CARBS} noThrow />
        <Route component={FoodExplorer} items={items} tags={tags} path=":mode/*" />
      </Router>
    </>
  );
}
