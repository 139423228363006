import { FoodExplorerTags } from "../types";

const toIdAndI18n = (t: FoodExplorerTags) => ({
  id: t.id,
  popularity: t.popularity,
  image: t.image,
  translation: t.translations[0],
});

const toSingleTag = ({ id, translation, popularity, image }: ReturnType<typeof toIdAndI18n>) => ({
  id,
  popularity,
  image,
  slug: translation?.tagSlug,
  label: translation?.tagTitle,
});

const sortByPopularity = (a: ReturnType<typeof toSingleTag>, b: ReturnType<typeof toSingleTag>) =>
  a.popularity - b.popularity;

export function useFoodExplorerTags(tags: FoodExplorerTags[]) {
  const modifiedTags = tags.flatMap(toIdAndI18n).map(toSingleTag).sort(sortByPopularity) ?? [];
  return [modifiedTags];
}
