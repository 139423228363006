import React, { useContext } from "react";
import { FoodExplorerStateContext } from "../FoodExplorerStateProvider";
import { useFoodExplorerModes } from "../hooks/useFoodExplorerModes";

export const FoodExplorerModeLegendHorizontal = React.memo(Legend);

function Legend() {
  const { modeId } = useContext(FoodExplorerStateContext);
  const { getModeByModeId } = useFoodExplorerModes();
  const mode = getModeByModeId(modeId);

  return (
    <div className="flex flex-col w-full">
      <div className="mb-0.5 flex w-full justify-between uppercase tracking-wider text-sm font-medium text-grey">
        <div className="flex">{mode?.legends[0]}</div>
        <div className="flex">{mode?.legends[1]}</div>
      </div>
      <div
        className="h-2 w-full rounded-md"
        style={{
          backgroundImage: `linear-gradient(
            to right,
            #F28E00 0%,
            #F2BD00 33%,
            #2FCE78 66%,
            #008E47 100%
          )`,
        }}
      ></div>
    </div>
  );
}
