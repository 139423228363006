import React from "react";
import { RouteComponentProps } from "@reach/router";

type RouteProps<Props> = RouteComponentProps & {
  component: React.ComponentType<Props>;
} & Props;

function Route<Props>({ component: Component, ...rest }: RouteProps<any>) {
  // FIXME: `props` will also contain all properties from `RouteComponentProps`.
  const props: Props = rest as any;
  return <Component {...props} />;
}

export default Route;
