import React, { ReactNode, useMemo } from "react";
import { FoodExplorerFoodItem } from "../types";
import { colorByCarbs } from "../utils";

export function itemWithCarbs(
  WrappedComponent: React.MemoExoticComponent<
    ({
      item,
      isLocked,
      children,
    }: {
      item: FoodExplorerFoodItem;
      isLocked: boolean;
      children: ReactNode;
    }) => JSX.Element
  >
) {
  return function WithCarbsNetGrams(props: { item: FoodExplorerFoodItem; isLocked: boolean }) {
    const { netCarbs } = props.item;
    const textColor = useMemo(() => colorByCarbs(netCarbs), [netCarbs]);
    return (
      <WrappedComponent item={props.item} isLocked={props.isLocked}>
        <div className="flex justify-center font-bold text-base" style={{ color: textColor }}>
          {Math.round(netCarbs)}
        </div>
      </WrappedComponent>
    );
  };
}
